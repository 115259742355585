const rateAbsRequiredTypes = ['BY_TX_COUNT', 'BY_TX_COUNT_SIMPLE', 'BY_TX_COUNT_COMPLEX', 'FIXED', 'FIXED_NO_PERIOD', 'MIN'];
const groupFinTermsValidationScheme = {
  name: ['groupFinTermNameMatchIsProhibited_withArgs'],
  payerId: ['required'],
  payeeId: ['required'],
  type: ['required'],
  source: ['required'],
  currency: ['required'],
  payoutPeriod: ['required'],
  sourceParams: ['oneInParamsArrRequired'],
  finTermsLength: ['notEmptyFinTermsArr'],
  finTerms: {
    addAgreementId: ['required'],
    startDate: ['dateFormat', 'required'],
    endDate: ['dateFormat'],
    dates: ['sequenceDatesInFinTerm', 'sequenceDatesAmongFinTerms'],
    finTermParamsLength: ['notEmptyFinTermParamsArr'],
    params: {
      paramsRow: ['oneInRatesArrRequired'],
      minAmount: ['required'],
      rateHours: ['required'],
      minCount: ['required'],
      rateAbs: (groupFinTermType) => {return rateAbsRequiredTypes.includes(groupFinTermType) ?  ['required'] : null;},
      minMaxParams: ['minMaxCompliance']
    }
  },
  gates: {
    terminalsLength: ['notEmptyTerminalsArr']
  }
};

export default groupFinTermsValidationScheme;