//@flow
import * as React from 'react';
import {connect} from "react-redux";
import type {sourceParamsLength} from "../../../../../../../../../services/flowTypes/componentFlowTypes";
import SourceParam from "./SourceParam/SourceParam";
import type {groupFinTermSource} from "../../../../../../../../../services/flowTypes/dataFlowTypes";
import getUniqueKey from "../../../../../../../../../utils/getUniqueKey";
import SourceParamsValidator from "./SourceParamsValidator";

type Props = {
  sourceParamsLength: sourceParamsLength,
  source: groupFinTermSource
};

class SourceParamsEdit extends React.Component <Props> {
  render() {
    const sourceParamContainerClassName = this.props.source === "PNE" || this.props.source === "PNE_EU" ? "mb_3du" :
      "w_half-card mb_3du";
    const sourceParamsContainerClassName = this.props.source === "PNE" || this.props.source === "PNE_EU" ? "" :
      "flex_jc-sb flex_wrap";
    let sourceParams = [];
    for (let i=0; i<this.props.sourceParamsLength; i++) {
      sourceParams.push(
        <div key={getUniqueKey()}  className={sourceParamContainerClassName}>
          <SourceParam sourceParamIndex={i} />
        </div>
      );
    }
    return (
      this.props.source.length && this.props.sourceParamsLength ? (
        this.props.source !== 'EMPTY' ? (
          <SourceParamsValidator>
            <div className={sourceParamsContainerClassName}>
              {sourceParams}
            </div>
          </SourceParamsValidator>
        ) : (
          <div className={sourceParamsContainerClassName}>
            {sourceParams}
          </div>
        )
      ) : null
    );
  }
}

const mapStateToProps = state => {
  return {
    sourceParamsLength: state.organization.item.sourceParamsEdit.length,
    source: state.organization.item.groupFinTermsEdit.source
  };
};
export default connect(mapStateToProps, null)(SourceParamsEdit);